/**
 * @file retrieves file name, extension, and embedded url from a file
 */
import { truncate } from '@@/bits/truncate'

const getExtension = function (file: File): string {
  if (file.name) {
    const filename = file.name
    const extensionStartIndex = (Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1
    let possibleExtension = filename.substr(extensionStartIndex)
    if (possibleExtension == '') {
      return possibleExtension
    }
    // remove query string
    possibleExtension = possibleExtension.replace(/\?.*$/, '')
    // if there are special characters, this is not an extension
    if (/[^a-zA-Z0-9]/.exec(possibleExtension) != null) {
      return ''
    } else {
      return possibleExtension.toLowerCase()
    }
  } else if (file.type) {
    // return png for image/png
    const [mimeType, charset] = file.type.split(';')
    const [mimeTypeWithoutPlus, _] = mimeType.split('+')
    const [mimeCategory, possibleExtension] = mimeType.split('/')
    if (possibleExtension) {
      return possibleExtension.toLowerCase()
    } else {
      return ''
    }
  } else {
    return ''
  }
}

const getName = function (file: File): string {
  const ext = getExtension(file)
  if (file.name) {
    const filename = file.name
    const splits = filename.split('.')
    const extension = splits.pop()
    let baseFilename = splits.join('_')
    // cleanup special characters. They create problems
    baseFilename = baseFilename.replace(/[^a-zA-Z0-9_]/g, '_')
    // limit length
    baseFilename = baseFilename.substr(0, 100)

    if (ext) {
      return [baseFilename, ext].join('.')
    } else {
      return baseFilename
    }
  } else if (ext) {
    return `file.${ext}`
  } else {
    return 'file'
  }
}

const cleanupFilename = function (originalName: string): string {
  const splits = originalName.split('.')
  const extension = splits.pop()
  let baseFilename = splits.join('_')
  // cleanup special characters. They create problems
  baseFilename = baseFilename.replace(/[^a-zA-Z0-9_]/g, '_')
  // limit length
  baseFilename = baseFilename.substr(0, 100)

  if (extension !== null) {
    return [baseFilename, extension].join('.')
  } else {
    return baseFilename
  }
}

interface UrlHash {
  url: string
}

const getEmbeddedUrl = async function (file: File): Promise<UrlHash> {
  return await new Promise((resolve, reject) => {
    const extension = getExtension(file)
    if (/gdoc|gsheet|glink|gform|gmap|gmaps|gslides|gdraw|url|htm|html|webloc|mht|mhtml/.exec(extension) != null) {
      let matches
      const reader = new FileReader()
      reader.onload = () => {
        const text: string = typeof reader.result !== 'string' ? '' : reader.result
        if (
          text !== '' &&
          (extension === 'gdoc' ||
            extension === 'gsheet' ||
            extension === 'glink' ||
            extension === 'gform' ||
            extension === 'gmap' ||
            extension === 'gmaps' ||
            extension === 'gslides' ||
            extension === 'gdraw')
        ) {
          const hash = JSON.parse(text)
          resolve({ url: hash.url })
        } else if (extension === 'url' && (matches = /URL=(.*)/.exec(text)) != null) {
          resolve({ url: matches[1] })
        } else if (
          (extension === 'htm' || extension === 'html') &&
          text.includes('window.googleJavaScriptRedirect') &&
          (matches = /content="0;URL='(.*)'/.exec(text)) != null
        ) {
          resolve({ url: matches[1] })
        } else if (
          (extension === 'htm' || extension === 'html') &&
          (matches = /<!--ssavedsfromsurl=((?:d+))(.*?)s-->/.exec(text)) != null &&
          matches[1] !== 'http://tiddlywiki.com'
        ) {
          resolve({ url: matches[1] })
        } else if (extension === 'webloc' && (matches = /<string>(.*)<\/string>/.exec(text)) != null) {
          resolve({ url: matches[1] })
        } else if (
          (extension === 'mht' || extension === 'mhtml') &&
          (matches = /Content-Location:s(.*)/.exec(text)) != null
        ) {
          resolve({ url: matches[1] })
        } else {
          reject()
        }
      }
      reader.onerror = (): void => {
        reject()
      }
      reader.readAsText(file)
    } else {
      reject()
    }
  })
}

interface ImageFileData {
  name: string
  dataUrl: string
  width: number
  height: number
}

/**
 * Reads the file data. Returns the file name, file content as data URL, and the image dimensions.
 * If the file is not an image, returns null.
 */
const readImageFileData = async (file: File): Promise<ImageFileData | null> => {
  return await new Promise((resolve) => {
    if (!file.type.startsWith('image/')) return resolve(null)
    const reader = new FileReader()
    reader.onload = () => {
      const dataUrl = reader.result as string
      const img = new Image()
      img.onload = () => {
        resolve({
          name: file.name,
          dataUrl,
          width: img.width,
          height: img.height,
        })
      }
      img.onerror = () => resolve(null)
      img.src = dataUrl
    }
    reader.onerror = () => resolve(null)
    reader.readAsDataURL(file)
  })
}

/**
 * Some regex is obtained from: https://github.com/hfour/sanitize-filename-ts/blob/master/src/index.ts
 *
 * This function replaces characters in strings that are illegal/unsafe for filenames.
 * Unsafe characters are either removed or replaced by a substitute set
 * in the optional `options` object.
 *
 * Illegal Characters on Various Operating Systems
 * / ? < > \ : * | "
 * https://kb.acronis.com/content/39790
 *
 * Unicode Control codes
 * C0 0x00-0x1f & C1 (0x80-0x9f)
 * http://en.wikipedia.org/wiki/C0_and_C1_control_codes
 *
 * Reserved filenames on Unix-based systems (".", "..")
 * Reserved filenames in Windows ("CON", "PRN", "AUX", "NUL", "COM1",
 * "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
 * "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", and
 * "LPT9") case-insesitively and with or without filename extensions.
 *
 * Capped at 255 characters in length.
 * http://unix.stackexchange.com/questions/32795/what-is-the-maximum-allowed-filename-and-folder-size-with-ecryptfs
 *
 * @param  {String} input   Original filename
 * @param  {Object} options {replacement: String}
 * @return {String}         Sanitized filename
 */
const illegalRe = /[\/\?<>\\:\*\|":]/g
// eslint-disable-next-line no-control-regex
const controlRe = /[\x00-\x1f\x80-\x9f]/g
const reservedRe = /^\.+$/
const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
const windowsTrailingRe = /[\. ]+$/
const containerRe = /[\(\)\[\]\{\}（）<>［］｛｝【】『』《》〈〉「」]/g

const replace = (input: string, replacement: string): string => {
  const sanitized = input
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement)
    .replace(containerRe, replacement)
  return truncate(sanitized, { length: 255 })
}

const sanitizeFilename = (input: string, replacement: string = ''): string => {
  const output = replace(input, replacement)
  if (replacement === '') {
    return output
  }
  return replace(output, '')
}

function base64ToFile(base64String: string, fileName: string, mimeType: string): File {
  // Decode the base64 string into a binary string
  const binaryString = atob(base64String)
  const len = binaryString.length
  const uint8Array = new Uint8Array(len)

  // Convert binary string to an array of bytes
  for (let i = 0; i < len; i++) {
    uint8Array[i] = binaryString.charCodeAt(i)
  }

  // Create a `File` object
  return new File([uint8Array], fileName, { type: mimeType })
}

export { base64ToFile, cleanupFilename, getEmbeddedUrl, getExtension, getName, readImageFileData, sanitizeFilename }
export type { ImageFileData }
