// @file Network requests related to collaborator surface

import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { WallAccessRight } from '@@/enums'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { Id } from '@@/types'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 5

class CollaboratorApi extends FetchableObject {
  public static async leave(wallId: Id, fetchOptions = {}): Promise<any> {
    return await fetchJson(`/api/${API_VERSION}/wall_collaborators/leave`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wall_id: wallId,
      }),
      ...fetchOptions,
    })
  }

  public static async create(
    { wallId, userId, right }: { wallId: Id; userId: Id; right: WallAccessRight },
    fetchOptions = {},
  ): Promise<any> {
    return await fetchJson(`/api/${API_VERSION}/wall_collaborators/create`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wall_id: wallId,
        user_id: userId,
        right,
      }),
      ...fetchOptions,
    })
  }
}

export { CollaboratorApi }
