// @file helpers for strings

// Most of the utility functions in this file are referenced from lodash

/** Used to compose unicode character classes. */
const rsAstralRange = '\\ud800-\\udfff'
const rsComboMarksRange = '\\u0300-\\u036f'
const reComboHalfMarksRange = '\\ufe20-\\ufe2f'
const rsComboSymbolsRange = '\\u20d0-\\u20ff'
const rsComboRange = rsComboMarksRange + reComboHalfMarksRange + rsComboSymbolsRange
const rsVarRange = '\\ufe0e\\ufe0f'

const rsAstral = '[' + rsAstralRange + ']'
const rsCombo = '[' + rsComboRange + ']'
const rsFitz = '\\ud83c[\\udffb-\\udfff]'
const rsModifier = '(?:' + rsCombo + '|' + rsFitz + ')'
const rsNonAstral = '[^' + rsAstralRange + ']'
const rsRegional = '(?:\\ud83c[\\udde6-\\uddff]){2}'
const rsSurrPair = '[\\ud800-\\udbff][\\udc00-\\udfff]'
const rsZWJ = '\\u200d'

const reOptMod = rsModifier + '?'
const rsOptVar = '[' + rsVarRange + ']?'
const rsOptJoin =
  '(?:' + rsZWJ + '(?:' + [rsNonAstral, rsRegional, rsSurrPair].join('|') + ')' + rsOptVar + reOptMod + ')*'
const rsSeq = rsOptVar + reOptMod + rsOptJoin
const rsSymbol = '(?:' + [rsNonAstral + rsCombo + '?', rsCombo, rsRegional, rsSurrPair, rsAstral].join('|') + ')'

const reHasUnicode = RegExp(`(?:${rsZWJ}|${rsAstral}|${rsCombo}|${rsVarRange})`)
const reUnicode = RegExp(rsFitz + '(?=' + rsFitz + ')|' + rsSymbol + rsSeq, 'g')

/**
 * Checks if `string` contains Unicode symbols.
 *
 * @param {string} string The string to inspect.
 * @returns {boolean} Returns `true` if a symbol is found, else `false`.
 */
function hasUnicode(string: string): boolean {
  return reHasUnicode.test(string)
}

/**
 * Gets the size of a Unicode `string`.
 *
 * @param {string} string The string inspect.
 * @returns {number} Returns the string size.
 */
function unicodeSize(string: string): number {
  let result = (reUnicode.lastIndex = 0)
  while (reUnicode.test(string)) {
    ++result
  }
  return result
}

/**
 * Gets the number of symbols in `string`.
 * Handles Unicode symbols.
 *
 * @param {string} string The string to inspect.
 * @returns {number} Returns the string size.
 */
function stringSize(string: string): number {
  return hasUnicode(string) ? unicodeSize(string) : string.length
}

/**
 * Converts `string` to an array of Unicode symbols.
 *
 * @param {string} string The string to convert.
 * @returns {Array} Returns the converted array.
 */
function unicodeToArray(string: string): string[] {
  return string.match(reUnicode) ?? []
}

export { hasUnicode, stringSize, unicodeToArray }
