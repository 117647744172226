/*
 * @file Provide Folder Mixins
 */
// Refer to NAME_MAX_LENGTH in app/models/folder.rb
export const MAX_FOLDER_NAME_LENGTH = 40

export function maxFolderNameLength(): number {
  return MAX_FOLDER_NAME_LENGTH
}

export function isValidFolderName(folderName: string | null): boolean {
  if (!folderName) return false
  const trimmedLength: number = folderName.trim().length
  return trimmedLength > 0 && trimmedLength <= MAX_FOLDER_NAME_LENGTH
}
