// @file helpers for truncating strings, used as a drop-in replacement for lodash.truncate

import { hasUnicode, stringSize, unicodeToArray } from '@@/bits/strings'

interface TruncateOptions {
  length?: number
  omission?: string
  separator?: RegExp | string
}

/**
 * Truncates `string` if it's longer than the given maximum string length.
 * The last characters of the truncated string are replaced with the omission
 * string which defaults to "...".
 *
 * Implementation is largely based on lodash.truncate,
 * and can be used as a drop-in replacement.
 *
 * @param {string} [string=''] - The string to truncate.
 * @param {Object} [options={}] - The truncate options.
 * @param {number} [options.length=30] - The maximum string length.
 * @param {string} [options.omission='...'] - The string to indicate text is omitted.
 * @param {RegExp|string} [options.separator] - The separator pattern to truncate to.
 * @returns {string} The truncated string.
 *
 * @example
 * const truncStr1 = truncate('hi-diddly-ho there, neighborino');
 * // returns 'hi-diddly-ho there, neighbo...'
 *
 * const truncStr2 = truncate('hi-diddly-ho there, neighborino', {
 *   'length': 24,
 *   'separator': ' '
 * });
 * // returns 'hi-diddly-ho there,...'
 *
 * const truncStr3 = truncate('hi-diddly-ho there, neighborino', {
 *   'length': 24,
 *   'separator': /,? +/
 * });
 * // returns 'hi-diddly-ho there...'
 *
 * const truncStr4 = truncate('hi-diddly-ho there, neighborino', {
 *   'omission': ' [...]'
 * });
 * // returns 'hi-diddly-ho there, neig [...]'
 */

function truncate(string: string, { length = 30, omission = '...', separator }: TruncateOptions = {}): string {
  // Coerce NaN to 0
  length = Number.isNaN(length) ? 0 : length

  if (string.length === 0) return string

  // Get the string length in a unicode-aware manner
  const strSymbols = hasUnicode(string) ? unicodeToArray(string) : undefined
  const strLength = strSymbols != null ? strSymbols.length : string.length

  // No truncation needed
  if (length >= strLength) {
    return string
  }

  let end = length - stringSize(omission)
  if (end < 1) {
    return omission
  }
  let result = strSymbols != null ? strSymbols.slice(0, end).join('') : string.slice(0, end)

  if (separator === undefined) {
    return result + omission
  }

  if (strSymbols != null) {
    end += result.length - end
  }

  // If there is a separator, we can truncate further up to the last occurrence of the separator
  if (separator instanceof RegExp) {
    const lastMatch = [...result.matchAll(new RegExp(separator.source, separator.flags.replace('g', '') + 'g'))].pop()
    result = result.slice(0, lastMatch?.index ?? end)
  } else {
    if (string.indexOf(separator, end) !== end) {
      const index = result.lastIndexOf(separator)
      if (index > -1) {
        result = result.slice(0, index)
      }
    }
  }

  return result + omission
}

export { truncate }
